<template>
	<SearchResultListItem
		:result="result"
		:is-mobile="isMobile"
		:list-type="result.listType"
		:is-location-card="true"
	>
		<template #row1>
			<div
				class="w-full min-w-full text-base text-left truncate"
				:class="isMobile ? 'ml-2 md:ml-0 self-end' : 'pb-1'"
			>
				<span class="flex items-center w-full">
					<img
						src="@/assets/icons/pin.svg"
						class="mr-2.5 ml-2"
						width="18"
						height="18"
						alt="location pin icon"
					>
					<span class="capitalize">
						{{ result.title }}
					</span>
				</span>
			</div>
		</template>
	</SearchResultListItem>
</template>

<script async>
import SearchResultListItem from '@/components/search/SearchResultListItem.vue'

export default {
	components: {
		SearchResultListItem
	},
	props: {
		result: {
			type: Object,
			required: true
		},
		listType: {
			type: String,
			required: true
		},
		isMobile: {
			type: Boolean,
			required: true
		}
	}
}
</script>
